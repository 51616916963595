<template lang="pug">
boxWrapper
  titleComponent(title="Ingredientes adaptados")
  .group-ingredients
    .ingredient(v-for="ingredient in ingredients", :key="ingredient.id")
      .name {{ ingredient.name }}
      .grams {{ ingredient.service }}
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import titleComponent from "./title-component.vue";
import boxWrapper from "./box-wrapper.vue";
export default {
  name: "ingredients-component",
  components: {
    titleComponent,
    boxWrapper,
  },
  setup() {
    const store = useStore();
    const ingredients = computed(() => {
      const ingredientsE = store.getters["menuReceiptStore/ingredients"];
      return ingredientsE.map((ingredient) => {
        const service =
          ingredient.servingSize[0].grams == 0
            ? ingredient.servingSize[0].qty +
              " " +
              ingredient.servingSize[0].units
            : ingredient.servingSize[0].grams.toFixed(2) + "gr";

        return {
          id: ingredient.ingredient_rel,
          name: ingredient.name,
          service,
        };
      });
    });

    return {
      ingredients,
    };
  },
};
</script>
<style lang="scss" scoped>
.group-ingredients {
  @apply my-4 bg-gray-50;
  .ingredient {
    @apply px-2 py-1 flex items-center justify-between text-sm;

    & + .ingredient {
      @apply border-t-2 border-gray-200 border-dashed;
    }
  }
}
</style>